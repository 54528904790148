@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  :host {
    /**
     * Tailwind uses a default `line-height` of `1.5`, however all of our Typography styles have a
     * line-height of `1.4`, so update the root `line-height` to be inline with that.
     */
    line-height: 1.4;
  }

  body {
    /**
     * Set the base `color` to `text-charcoal-500`, and also apply `antialiased` to the body.
     */
    @apply text-charcoal-500;
    @apply antialiased;
  }
}
