.checkout--index {
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
  width: 66.666vw;
}
.checkout--header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(50vw - 15px);
  z-index: 100;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 30%, rgb(0, 0, 0) 100%);
}
.checkout--footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50vw;
  z-index: 50;
}
.checkout--main {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout--main .raylo-modal {
  width: 66.666vw;
  height: 100vh;
}
@media screen and (max-width: 1024px) {
  .checkout--main .raylo-modal {
    width: 100vw;
  }
}
.checkout--step {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
  position: relative;
  transition: height 200ms ease-in-out;
}
.checkout--summary {
  background: #fff;
  position: fixed;
  top: var(--nav-bar-height);
  left: 0;
  width: 66.666vw;
  height: calc(100dvh - var(--nav-bar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  scrollbar-color: rgb(var(--theme-checkout-summary-scrollbar-thumb))
    rgb(var(--theme-checkout-summary-scrollbar-track));
  scrollbar-width: thin;

  z-index: 75;
  transform: translateY(-110%);
  transition: transform 0.2s ease-in-out;
}
.checkout--summary.showSummary {
  display: block;
}

.checkout--index.showSummary .checkout--main {
  display: none;
}

.checkout .checkout--index:not(.success).showSummary .checkout--summary {
  transform: translateY(0px);
}

.checkout--summary::-webkit-scrollbar {
  width: 9px;
}
.checkout--summary::-webkit-scrollbar-track {
  background: none;
}
@media screen and (max-width: 1024px) {
  .checkout .checkout--header {
    background: none;
    width: 100vw;
  }
  .checkout .checkout--index:not(.success) {
    width: 100vw;
  }
  .checkout .checkout--index:not(.success) .checkout--summary {
    width: 100vw;
  }
  .checkout .checkout--index:not(.success) .checkout--footer {
    width: 100vw;
  }
}

@media screen and (max-width: 1024px) {
  .checkout--index.showSummary {
    padding-bottom: 0;
  }
  .checkout--index.showSummary .checkout--main,
  .checkout--index.showSummary .checkout--footer {
    display: none;
  }
}

.checkout--index.success {
  background: #404eff;
}
.checkout--index.success .checkout--step {
  max-width: 350px;
  padding-left: 20px;
  padding-right: 20px;
}
.checkout--index.success .checkout--step,
.checkout--index.success .checkout--summary {
  transition: none;
}
@media screen and (max-width: 700px) {
  .checkout--index.success {
    width: 100vw;
    flex-direction: column;
  }
  .checkout--index.success .checkout--main {
    flex: auto;
    height: auto;
    min-height: auto;
  }
  .checkout--index.success .checkout--summary {
    position: relative;
    height: auto;
    min-height: auto;
  }
}

.phone-configure--image {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
}
.phone-configure--form {
  margin-left: 50vw;
  width: calc(50vw - 40px);
}
@media screen and (max-width: 1024px) {
  .phone-configure {
    min-width: 400px;
  }
  .phone-configure .phone-configure--image {
    position: relative;
    display: none;
  }
  .phone-configure .phone-configure--form {
    width: auto;
    margin-left: 0;
  }
}
@media screen and (max-width: 540px) {
  .phone-configure {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .phone-configure {
    min-width: 0px;
  }
}

.blocks-animation {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 100vh;
  padding: 40px;
}
.blocks-animation > div {
  width: 80% !important;
}
@media screen and (max-width: 1024px) {
  .blocks-animation {
    display: none;
  }
}
